import React from "react"
import Navigation from './Navigation'

class MainHeader extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Navigation />
      </React.Fragment>
    )
  }
}

export default MainHeader

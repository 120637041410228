import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import Layout from "../components/Layout/layout";

import config from "../../gatsby-config";
import MainHeader from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

const UseCases = () => (
  <Layout>

    <React.Fragment>

      <Helmet>
        <title>{`Use Cases | ${config.siteMetadata.siteTitle}`}</title>
      </Helmet>

      <main>

        <MainHeader />

        <BodyContainer>
          <h2>Use Cases</h2>

          <h3>How To:</h3>

          <h4>Create case in Scalepoint?</h4>

          <h5>Use Case Integration API</h5>

          <ul>
            <li><a href="/case-integration-api#overview">Overview</a></li>
            <li><a href="/case-integration-api#case-integration-api-endpoint">Case Integration API endpoint</a></li>
            <li><a href="/api/case-integration-api">API Reference in Open API Format</a></li>
          </ul>

          <h4>Get Notification about event from Scalepoint, Send Event to Scalepoint?</h4>

          <h5>Use Events API</h5>

          <ul>
            <li><a href="/events-api">Overview</a></li>
            <li><a href="/events-api#supported-events">Supported events</a></li>
            <li><a href="/api/events-api">API Reference in Open API Format</a></li>
          </ul>
        </BodyContainer>

      </main>

      <Footer />

    </React.Fragment>

  </Layout>
)

const BodyContainer = styled.div`
  padding: 0 200px 35px 200px;
`

export default UseCases;

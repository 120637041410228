import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

export default () => {
  const data = useStaticQuery(graphql`
      query FooterQuery {
        site {
          siteMetadata {
            copyright
          }
        }
      }
    `
  )

  return (
    <Footer>
      {data.site.siteMetadata.copyright}
    </Footer>
  )
}

const Footer = styled.footer`
  font-size: 1.4rem;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  height: 100px;
  background-image: url('/logos/Scalepoint-logo-Blue.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 28px;
`
